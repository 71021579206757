@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&display=swap");

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 70%; /* 1rem = 11px */
	-webkit-tap-highlight-color: #0000;
	scroll-behavior: smooth;
}

@media screen and (max-width: 1550px) {
	html {
		font-size: 62.5% !important; /* 1rem = 10px */
	}
}

@media screen and (max-width: 1100px) {
	html {
		font-size: 56% !important; /* 1rem = 9px */
	}
}

@media screen and (max-width: 800px) {
	html {
		font-size: 50% !important; /* 1rem = 8px */
	}
}

@media screen and (max-width: 400px) {
	html {
		font-size: 37% !important; /* 1rem = 6px */
	}
}

body {
	box-sizing: border-box;
	overflow-x: hidden;
	// padding-bottom: 22rem !important; // height of footer
	font-family: "Nunito", sans-serif !important;
	position: relative;
	min-height: 100vh;

	// @media screen and (max-width: 800px) {
	// 	padding-bottom: 35rem !important;
	// }

	// @media screen and (max-width: 706px) {
	// 	padding-bottom: 45rem !important;
	// }

	// @media screen and (max-width: 316px) {
	// 	padding-bottom: 50rem !important;
	// }
}

a {
	text-decoration: none;
}

::selection {
	background: #9e7160 !important;
	color: #fff !important;
}
